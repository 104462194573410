.app__container {
  max-width: 1080px;
  margin: auto;
}

.loader {
  border: 10px solid #f3f3f3;
  border-top: 10px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  width: 50px;
  height: 50px;
  position: fixed;
  top: calc(50% - 40px);
  left: calc(50% - 40px);
}

.header {
  border-bottom: 1px solid var(--main-font-color);
}

.header__wrapper {
  padding: 10px 0;
  display: flex;
  flex-wrap: nowrap;
  max-width: 1080px;
  margin: 0 auto;
}

.header__logolink {
  margin: 0 auto;
  display: flex;
  align-items: center;
}

.header__logolink:hover {
  opacity: initial;
}

.nav__hamburger {
  padding: 10px 0;
  cursor: pointer;
}

.nav__hamburger svg {
  width: 20px;
  height: 20px;
}

.menu {
  position: fixed;
  visibility: hidden;
  top: 0;
  left: -650px;
  background-color: var(--main-bg-color);
  height: 100%;
  width: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  text-align: left;
  transition: left 200ms ease-out;
}

.menu.visible {
  width: 35%;
  visibility: visible;
  left: 0;
}

nav .menu__list {
  margin-top: 22px;
  padding-left: 15px;
}

nav .meny__listitem {
  padding-bottom: 25px;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  align-items: center;
  display: flex;
}

nav .meny__listitem svg {
  padding-right: 30px;
  margin-left: auto;
}

nav .meny__listitem a {
  width: 100%;
  height: 100%;
  align-items: center;
  display: flex;
}

.menumask {
  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--grey);
  opacity: 0;
  visibility: hidden;
  height: 100%;
  width: 100%;
  z-index: 10;
}

.menumask.visible {
  opacity: 0.7;
  visibility: visible;
}

.menu__close {
  position: absolute;
  top: 15px;
  left: 20px;
  cursor: pointer;
  width: 28px;
  height: 28px;
  opacity: 0.3;
}

.menu__close:hover {
  opacity: 1;
}
.menu__close:before,
.menu__close:after {
  position: absolute;
  left: 15px;
  content: " ";
  height: 24px;
  width: 2px;
  background-color: #333;
}

.menu__close:before {
  transform: rotate(45deg);
}

.menu__close:after {
  transform: rotate(-45deg);
}

.meny__listitemlink {
  width: 100%;
}

.menu__visible_toprow {
  display: flex;
  justify-content: flex-end;
  height: 52px;
}

.menu__visible_toprow svg {
  margin: 10px 18px 0 0;
}

.showmenu__logoutbutton {
  margin-top: auto;
  text-align: end;
  padding: 30px;
}

.share__button {
  margin-left: 10px;
}

.card {
  scroll-snap-align: start;
  flex-shrink: 0;
  max-width: 295px;
  width: 100%;
  min-height: 416px;
  margin-right: 50px;
  transform-origin: center center;
  transform: scale(1);
  transition: transform 0.5s;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 10px;
}

.card.card--red {
  /* background-image: url("/src/assets/card-red2.png"); */
  background-image: url("/src/assets/Kort_Matklubben.svg");
  background-repeat: no-repeat;
  border-radius: 20px;
}

.card.card--blue {
  /* background-image: url("/src/assets/card-blue2.png"); */
  background-image: url("/src/assets/Kort_Foretagskort.svg");
  background-repeat: no-repeat;
  border-radius: 20px;
}

.card.card--green {
  /* background-image: url("/src/assets/card-green2.png"); */
  background-image: url("/src/assets/Kort_Kundkort.svg");
  background-repeat: no-repeat;
  border-radius: 20px;
}

.card__info {
  font-size: 18px;
}

.card__imagewrapper {
  flex: 100%;
}

.card__imagewrapper img {
  max-width: 156px;
  height: auto;
  border-radius: 20px;
}

.card__body {
  width: 100%;
  height: 413px;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  box-shadow: 4px 4px 4px rgb(0 0 0 / 15%);
}

.card__heading {
  margin: 0;
  padding: 10px 0;
  font-size: 24px;
  text-transform: uppercase;
  word-break: break-all;
}

.card__image {
  width: 100%;
  height: 100%;
}

.card__infowrapper {
  text-align: left;
  padding: 20px 0 45px 20px;
  color: var(--white);
}

.card__inactive-sticker {
  max-height: 136px;
  max-width: 136px;
  position: relative;
  bottom: 150px;
  right: 40px;
  bottom: 55px;
}

.user {
  padding-left: 20px;
}

.user__name {
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
  color: var(--black);
  padding-bottom: 20px;
}

.user .user__info .user__message {
  font-size: 20px;
  line-height: 34px;
  font-weight: 700;
  color: var(--black);
}

.myprofile__wrapper {
  text-align: left;
  padding: 0;
}

.myprofile_heading {
  margin-bottom: 30px;
}

.myprofile label {
  font-weight: var(--font-weight-bold);
  font-size: 20px;
  line-height: 34px;
  font-weight: 700;
}

.myprofile .info__wrapper {
  margin-bottom: 20px;
}

.myprofile__infotext {
  position: absolute;
  bottom: 1rem;
  height: 60px;
}

.myprofile__infotext p {
  margin: 10px 0;
}

.notfound {
  margin: 0 3rem;
}

.notfound h1 {
  margin-top: 5rem;
}

.qrcode__button {
  display: none;
}

.install__pwa {
  padding: 10px 0;
  cursor: pointer;
  display: none;
}

.install__pwa i {
  margin-right: 10px;
}

.install__pwa svg {
  width: 24px;
  height: 24px;
}

.install__pwa svg {
  height: 18px;
  width: 18px;
}

.share__btn {
  background-color: black;
  color: white;
  border: none;
  border-radius: 3px;
  height: 40px;
  width: 100px;
}

.slider {
  width: 300px;
  text-align: center;
  overflow: hidden;
  margin: 0 auto;
}

.slides {
  display: block;
  overflow-x: none;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
}
.slides::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.slides::-webkit-scrollbar-thumb {
  background: var(--main-font-color);
  border-radius: 10px;
}
.slides::-webkit-scrollbar-track {
  background: transparent;
}
.slides.noOverflowX {
  overflow-x: hidden;
}

.author-info {
  background: rgba(0, 0, 0, 0.75);
  color: white;
  padding: 0.75rem;
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  margin: 0;
}

.author-info a {
  color: white;
}

.slider > a {
  display: inline-flex;
  width: 1.5rem;
  height: 1.5rem;
  background: white;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 0 0 0.5rem 0;
  position: relative;
}

.slider > a:active {
  top: 1px;
}

.slider > a:focus {
  background: #000;
}

.card__footer {
  flex: 100%;
  display: flex;
  padding: 0.5rem 0 0.5rem 0;
  min-width: 100%;
  margin-top: auto;
  box-sizing: border-box;
}

.card__buttonwrapper {
  margin-left: auto;
  display: flex;
}

.card__inactivewrapper {
  padding: 20px 0;
  width: 20% auto;
  margin: auto 20%;
}

.card__inactivatedwrapper {
  position: relative;
  bottom: 150px;
}

.fa-chevron-right {
  color: var(--arrow-green);
}

.link-home {
  text-decoration: underline;
  color: var (--card-blue-color);
}

#Artwork {
  display: none;
}

@media only screen and (min-width: 1280px) {
  .qrcode__button {
    display: block;
    width: 100px;
    height: 40px;
    background: black;
    padding: 8px;
    text-align: center;
    border-radius: 3px;
    color: white;
    line-height: 23px;
    margin-right: 5px;
    font-size: 15px;
    font-weight: 400;
  }

  .nav__hamburger svg {
    height: 22px;
    width: 22px;
  }

  .header__logolink svg {
    min-width: 160px;
    min-height: 40px;
  }

  #Artwork {
    cursor: pointer;
  }
}

@media only screen and (max-width: 1080px) {
  .menu.visible {
    width: 100%;
  }

  .nav__hamburger {
    position: absolute;
    top: 5px;
    left: 20px;
  }

  .install__pwa {
    right: 20px;
    position: absolute;
    top: 10px;
  }

  .myprofile__wrapper {
    padding-left: 20px;
  }
}

@media screen and (max-width: 768px) {
  .install__pwa {
    display: block;
  }
}

@media only screen and (max-width: 576px) {
  .user__message {
    padding-left: 40px;
  }

  .myprofile label {
    font-size: 16px;
  }

  .nav__hamburger svg {
    width: 22px;
    height: 22px;
  }

  .install__pwa svg {
    width: 20px;
    height: 20px;
  }

  .slides {
    display: flex;
    overflow-x: auto;
  }

  #Artwork {
    display: block;
  }
}

@media only screen and (max-width: 360px) {
  .user__message {
    padding-left: 0;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
