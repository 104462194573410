* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  --main-bg-color: #faf8f7;
  --main-font-color: #563331;
  --card-green-color: #00965e;
  --card-blue-color: #00b2a9;
  --card-red-color: #ff5e57;
  --arrow-green: #008f70;
  --font-weight-bold: 700;
  --white: #f9f9f9;
  --black: #36383f;
  --grey: #85888c;
}

html,
body {
  height: 100%;
}

body {
  font-size: 16px;
  color: var(--main-font-color);
  font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
  background: var(--main-bg-color);
}

a {
  text-decoration: none;
  color: var(--main-font-color);
  cursor: pointer;
}

button {
  cursor: pointer;
}

button:hover,
a:hover {
  opacity: 0.7;
}

button:disabled {
  opacity: 0.3;
  cursor: default;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.font--bold {
  font-weight: var(--font-weight-bold);
}

h1,
h2,
h3,
h4 {
  margin: 0;
  color: var(--main-font-color);
}

section {
  padding: 12px 0;
}

.hidden {
  display: none;
}

.opacity {
  filter: opacity(40%);
}
